import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './core/guard/authentication.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./features/home/home.module').then((module) => module.HomeModule),
  },
  {
    path: 'about-us',
    loadChildren: () =>
      import('./features/about-us/about-us.module').then(
        (module) => module.AboutUsModule
      ),
  },
  {
    path: 'careers',
    loadChildren: () =>
      import('./features/careers/careers.module').then(
        (module) => module.CareersModule
      ),
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./features/contact/contact.module').then(
        (module) => module.ContactModule
      ),
  },
  {
    path: 'news',
    loadChildren: () =>
      import('./features/news/news.module').then((module) => module.NewsModule),
  },
  {
    path: 'projects',
    loadChildren: () =>
      import('./features/projects/projects.module').then(
        (module) => module.ProjectsModule
      ),
  },
  {
    path: 'expertises',
    loadChildren: () =>
      import('./features/services/expertises.module').then(
        (module) => module.ExpertisesModule
      ),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./features/auth/auth.module').then((module) => module.AuthModule),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./features/privacy-policy/privacy-policy.module').then(
        (module) => module.PrivacyPolicyModule
      ),
  },
  {
    path: 'terms-conditions',
    loadChildren: () =>
      import('./features/terms-conditions/terms-conditions.module').then(
        (module) => module.TermsConditionsModule
      ),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./features/admin/admin.module').then(
        (module) => module.AdminModule
      ),
    canActivate: [AuthenticationGuard],
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
