export const LANGUAGES = [
  {
    name: 'English',
    value: 'en',
    flag: 'fi fi-gb',
  },
  {
    name: 'Albanian',
    value: 'al',
    flag: 'fi fi-al',
  },
];
