import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { GlobalHttpService } from '../services/global-http.service';
import * as GlobalActions from './global.actions';

@Injectable()
export class GlobalEffects {
  constructor(
    private http: GlobalHttpService,
    private actions$: Actions,
    private _snackBar: MatSnackBar
  ) {}

  loadTranslation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalActions.loadTranslation),
      switchMap((action) =>
        this.http.getTranslation(action.language).pipe(
          map((data) => GlobalActions.loadTranslationSuccess({ data })),
          catchError((err) => of(GlobalActions.loadTranslationError()))
        )
      )
    )
  );

  sendEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalActions.sendEmail),
      switchMap((action) =>
        this.http.sendEmail(action).pipe(
          map(() => GlobalActions.sendEmailSuccess()),
          catchError((error) => of(GlobalActions.sendEmailError(error)))
        )
      ),
      tap((action) => this.openEmailSnackBar(action))
    )
  );

  sendResume$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalActions.sendResume),
      switchMap((action) =>
        this.http.sendResume(action.formData).pipe(
          map(() => GlobalActions.sendResumeSuccess()),
          catchError((error) => of(GlobalActions.sendResumeError(error)))
        )
      ),
      tap((action) => this.openEmailSnackBar(action))
    )
  );

  fetchUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalActions.fetchProfile),
      switchMap((_) =>
        this.http.profile().pipe(
          map((payload) => {
            return GlobalActions.fetchProfileSuccess({ payload });
          }),
          catchError((error) => {
            return of(GlobalActions.fetchProfileError({ error }));
          })
        )
      )
    )
  );

  fetchAllProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalActions.fetchAllProjects),
      switchMap((_) =>
        this.http.fetchAllProjects().pipe(
          map((response) =>
            GlobalActions.fetchAllProjectsSuccess({ response })
          ),
          catchError((err) => of(GlobalActions.fetchAllProjectsError(err)))
        )
      )
    )
  );

  fetchAllExpertises$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalActions.fetchAllExpertises),
      switchMap((_) =>
        this.http.fetchAllExpertises().pipe(
          map((response) =>
            GlobalActions.fetchAllExpertisesSuccess({ response })
          ),
          catchError((err) => of(GlobalActions.fetchAllExpertisesError(err)))
        )
      )
    )
  );

  fetchActiveDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalActions.fetchActiveDetails),
      switchMap((_) =>
        this.http.fetchActiveDetails().pipe(
          map((response) =>
            GlobalActions.fetchActiveDetailsSuccess({ response })
          ),
          catchError((err) => of(GlobalActions.fetchActiveDetailsError(err)))
        )
      )
    )
  );

  deleteMedia$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalActions.deleteMedia),
      switchMap((action) =>
        this.http.deleteMedia(action.existMedia).pipe(
          map((response) => {
            this.openSnackBar(response.message, 'Dismiss');
            return GlobalActions.deleteMediaSuccess({ response });
          }),
          catchError((error) => of(GlobalActions.deleteMediaError({ error })))
        )
      )
    )
  );

  fetchAllNews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalActions.fetchAllNews),
      switchMap((_) =>
        this.http.fetchAllNews().pipe(
          map((response) => GlobalActions.fetchAllNewsSuccess({ response })),
          catchError((err) => of(GlobalActions.fetchAllNewsError(err)))
        )
      )
    )
  );

  fetchAllCareers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalActions.fetchAllCareers),
      switchMap((_) =>
        this.http.fetchAllCareers().pipe(
          map((response) => GlobalActions.fetchAllCareersSuccess({ response })),
          catchError((err) => of(GlobalActions.fetchAllCareersError(err)))
        )
      )
    )
  );

  deleteDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalActions.deleteDetails),
      switchMap((action) =>
        this.http.deleteDetails(action.nameList, action.detailsId).pipe(
          map((response) => {
            this.openSnackBar(response.message, 'Dismiss');
            return GlobalActions.deleteDetailsSuccess({ response });
          }),
          catchError((error) => {
            this.openSnackBar(error.error.message, 'Dismiss');
            return of(GlobalActions.deleteDetailsError({ error }));
          })
        )
      )
    )
  );

  createDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalActions.createDetails),
      switchMap((action) =>
        this.http.createDetails(action.details).pipe(
          map((response) => {
            this.openSnackBar(response.message, 'Dismiss');
            return GlobalActions.createDetailsSuccess({ response });
          }),
          catchError((error) => of(GlobalActions.createDetailsError({ error })))
        )
      )
    )
  );

  private openSnackBar(message: string, action: string): void {
    this._snackBar.open(message, action, {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      duration: 5000,
    });
  }

  private openEmailSnackBar(action: any): void {
    if (action.error) {
      this._snackBar.open('Email was not sent, please try again!', 'Dismiss', {
        duration: 3000,
      });
      return;
    }

    this._snackBar.open('Email was sent successfully!', 'Dismiss', {
      duration: 3000,
    });
  }
}
