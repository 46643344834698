import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Project } from '../../features/projects/interfaces/project.interface';
import {
  ActiveDetails,
  Career,
  CustomHttpResponse,
  Expertise,
  News,
  Profile,
} from '../../shared/interfaces/shared-interface';
import { Key } from '../enum/global.enum';

@Injectable()
export class GlobalHttpService {
  private readonly server: string = environment.domain;
  private readonly email_api: string = environment.email_api;

  constructor(private http: HttpClient) {}

  public getTranslation(language: string): Observable<any> {
    return this.http.get<any>(`assets/translations/${language}.json`);
  }

  public sendEmail(emailData: any): Observable<any> {
    return this.http.post<any>(`${this.email_api}/send-email`, emailData);
  }

  public sendResume(formData: any): Observable<any> {
    console.log(formData);
    return this.http.post<any>(`${this.email_api}/send-resume`, formData);
  }

  public fetchAllProjects(): Observable<Project[]> {
    return this.http.get<Project[]>(`${this.server}/projects/all`);
  }

  public fetchAllNews(): Observable<News[]> {
    return this.http.get<News[]>(`${this.server}/news/all`);
  }

  public fetchAllExpertises(): Observable<Expertise[]> {
    return this.http.get<Expertise[]>(`${this.server}/expertise/all`);
  }

  public fetchAllCareers(): Observable<Career[]> {
    return this.http.get<Career[]>(`${this.server}/careers/all`);
  }

  public profile(): Observable<CustomHttpResponse<Profile>> {
    return this.http.get<CustomHttpResponse<Profile>>(
      `${this.server}/user/profile`,
      {}
    );
  }

  public fetchActiveDetails(): Observable<ActiveDetails> {
    return this.http.get<ActiveDetails>(`${this.server}/projects/details`);
  }

  public deleteMedia(existMedia: string): Observable<CustomHttpResponse<any>> {
    return this.http.delete<CustomHttpResponse<any>>(
      `${this.server}/projects/delete/media/${existMedia}`
    );
  }

  public createDetails(details: any): Observable<CustomHttpResponse<any>> {
    return this.http.post<CustomHttpResponse<any>>(
      `${this.server}/projects/details/create`,
      details
    );
  }

  public deleteDetails(
    nameList: string,
    detailsId: number
  ): Observable<CustomHttpResponse<any>> {
    return this.http.delete<CustomHttpResponse<any>>(
      `${this.server}/projects/details/delete/${nameList}/${detailsId}`
    );
  }

  public refreshToken$ = () => <Observable<any>>this.http
      .get<any>(`${this.server}/user/refresh/token`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(Key.REFRESH_TOKEN)}`,
        },
      })
      .pipe(
        tap((response: any) => {
          localStorage.setItem(Key.TOKEN, response.data.access_token);
        })
      );
}
