@if (isInitSuccessfully) {
  @if (!isLoginActive) {
    <app-nav-header *ngIf="!isAdminActive"></app-nav-header>
  }
  <router-outlet></router-outlet>
  <app-footer *ngIf="!isAdminActive"></app-footer>
}
<div [ngClass]="{ fade: isAnimationProcessActive }">
  <div
    class="logo-container absolute h-[100vh] w-[100%] flex justify-center items-center"
    [ngClass]="{ 'fade-out': isFadeOut, 'fade-in': !isFadeOut }"
    [ngStyle]="{ 'z-index': isFadeOut ? 0 : 999 }">
    <div class="relative flex justify-center items-center w-full h-full">
      <canvas
        #canvas
        class="lg:w-[30%] lg:h-[40%] md:w-[30%] md:h-[40%] sm:w-[40%] sm:h-[50%] w-[90%] h-[90%]"></canvas>
    </div>
  </div>
</div>
