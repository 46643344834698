import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, throwError } from 'rxjs';
import { Key } from '../../global/enum/global.enum';
import { GlobalHttpService } from '../../global/services/global-http.service';
import { HelperService } from '../../global/services/helper.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private _nativeWindow: Window = window;

  get nativeWindow(): Window {
    return this._nativeWindow;
  }

  constructor(
    private userService: GlobalHttpService,
    private helperService: HelperService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
    if (this.shouldBypass(request.url)) {
      return next.handle(request);
    }

    const reqWithCredentials = request.clone({ withCredentials: true });
    const authorizedRequest = this.addAuthorizationTokenHeader(
      reqWithCredentials,
      localStorage.getItem(Key.TOKEN)
    );

    return next.handle(authorizedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          error.status === 401 ||
          error.status === 403 ||
          error.status === 500
        ) {
          localStorage.removeItem(Key.TOKEN);
          localStorage.removeItem(Key.REFRESH_TOKEN);
          location.reload();
        }
        return throwError(error);
      })
    );
  }

  private shouldBypass(url: string): boolean {
    return (
      url.includes('login') ||
      url.includes('refresh') ||
      url.includes('careers/all') ||
      url.includes('expertise/all') ||
      url.includes('projects/all') ||
      url.includes('news/all') ||
      url.includes('send-email') ||
      url.includes('send-resume')
    );
  }

  private addAuthorizationTokenHeader(
    request: HttpRequest<unknown>,
    token: string | null
  ): HttpRequest<any> {
    return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }
}
