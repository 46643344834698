import { CommonModule } from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  Actions,
  EffectsModule,
  EffectSources,
  EffectsRunner,
} from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AuthenticationGuard } from './core/guard/authentication.guard';
import { GlobalHttpService } from './global/services/global-http.service';
import { GlobalStoreService } from './global/services/global-store.service';
import { HelperService } from './global/services/helper.service';
import { GlobalEffects } from './global/store/global.effects';
import { globalReducer } from './global/store/global.reducer';
import { TitleService } from './shared/services/title.service';
import { SharedModule } from './shared/shared.module';

const effectProviders = [EffectsRunner, EffectSources, Actions];
effectProviders.forEach((p) => (p.ɵprov.providedIn = null));

const app_modules = [
  AppRoutingModule,
  CoreModule,
  SharedModule,
  CommonModule,
  EffectsModule.forRoot(effectProviders),
  StoreModule.forRoot({ globalState: globalReducer }),
  EffectsModule.forFeature(GlobalEffects),
];

const components = [AppComponent];

@NgModule({
  declarations: [...components],
  imports: [...app_modules],
  bootstrap: [AppComponent],
  providers: [
    provideAnimationsAsync(),
    provideHttpClient(),
    GlobalHttpService,
    GlobalStoreService,
    AuthenticationGuard,
    HelperService,
    TitleService,
  ],
})
export class AppModule {}
